<template>
  <div class="login-container">
    <img
      class="h-full"
      id="illustration"
      @load="getImageInfo"
      style="position: absolute; opacity: 0; z-index: -1"
      src="../../assets/illustration.png"
    />
    <div class="login-form">
      <div class="login-detail">
        <div style="text-align: left">
          <div
            class="mx-auto"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 25px;
            "
          >
            <img
              src="../../assets/login_logo.png"
              style="width: 138px; height: auto; object-fit: cover"
            />
            <p class="publisher">Agent</p>
          </div>
        </div>
        <div class="login-email">
          <img class="email-icon" src="../../assets/email.png" />
          <el-input v-model="email" placeholder="Please Input Your Email" />
        </div>
        <div class="login-btn" @click="doLogin">Sign in</div>
        <div class="login-tips text-center">
          A temporary pincode will be sent to your email, A new Refff account will be created upon your first-time login.
        </div>
      </div>
    </div>
    <div
      class="swiper-container"
      :style="{ width: swiperContainerWidth + 'px' }"
    >
      <img
        class="h-full"
        style="position: absolute"
        src="../../assets/illustration.png"
      />
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in 2"
          :key="index"
          class="slide-item"
        >
          <!-- <img class="h-full" id="illustration" src="../../assets/illustration.png" /> -->
          <img
            class="guide-image absolute"
            :style="{ width: guideWidth + 'px' }"
            v-if="index == 0"
            src="../../assets/guide.png"
          />
          <img
            class="guide-image2 absolute"
            :style="{ width: guide2Width + 'px' }"
            v-else
            src="../../assets/guide2.png"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { createLoginPinCode } from "../../api/reff";
import "../../assets/common/font.css";
import { setUserEmail, setUserId, setUserType,setPublisherEmail } from "../../utils/store";
import { Loading } from "element-ui";
//测试账号:yinzehu@sina.cn 008822
export default {
  name: "InputEmail",
  data() {
    return {
      swiperOptions: {
        // Swiper options here
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        }, // 自动轮播
        speed: 3000, // 轮播速度
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },
      isMobile: false,
      email: "",

      guideWidth: 0,
      guide2Width: 0,
      swiperContainerWidth: 0,
    };
  },
  components: {},
  mounted() {
    if (false) {
      // let user_id = "629eb1f4a4640bec2e8b4574";
      // let email = "yinzehu@sina.cn";
      let user_id = "6618a4cb94c784e7248b4567";
      let email = "s695870364@gmail.com";
      setUserId(user_id);
      setPublisherEmail(email)
      setUserType("publisher");
      this.$UserData.user_email = email;
      this.$message.success("Login Success");
      this.$router.replace("/publisher/my-articles");
      return;
    }
    this.isMobile = this.$isMobile;
  },
  methods: {
    getImageInfo() {
      const width = document.getElementById("illustration").offsetWidth;
      this.swiperContainerWidth = width;
      this.guideWidth = width * 0.62;
      this.guide2Width = width * 0.86;
    },

    gotoInputPwd() {
      this.$router.push({
        path: "/login-publisher/pwd",
        query: {
          email: this.email,
        },
      });
    },
    doLogin() {
      if (this.email == "") {
        this.$message.error("Please input email");
        return;
      }
      const loadingInstance = Loading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
        spinner: "el-icon-loading",
        text: "Loading data",
      });
      // this.gotoInputPwd()
      createLoginPinCode(this.email, "publisher")
        .then((res) => {
          this.$message.success("OTP sent, Please check your email");
          this.gotoInputPwd();
        })
        .finally(() => loadingInstance.close());
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.login-detail {
  width: 388px;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form {
  flex: 1;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";

  .publisher {
    background-color: #ededed;
    color: #b0b0b0;
    border-radius: 8px;
    padding: 2px 10px;
    box-sizing: border-box;
    margin-left: 15px;
  }

  .title {
    font-size: 32px;
    color: #024b53;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 16px;
  }

  .sub-title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.3);
    line-height: 1.2;
  }

  .login-email {
    width: 400px;
    background: #f5f5f5;
    border: 2px solid #005a64;
    margin: 32px 0 24px;
    border-radius: 12px;
    height: 64px;
    display: flex;
    align-items: center;

    .email-icon {
      width: 20px;
      height: 16px;
      margin-left: 12px;
    }

    &::v-deep .el-input__inner {
      width: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      background: transparent;
    }
  }

  .login-btn {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    background: #e2ff54;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 24px;
  }

  .login-tips {
    font-size: 16px;
    color: rgba(74, 85, 104, 0.3);
    line-height: 1.2;
  }
}

.swiper-container {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .slide-item {
    height: 100%;
    position: relative;

    .guide-image {
      position: absolute;
      width: 300px;
      height: auto;
      top: 4vh;
      left: 50%;
      transform: translateX(-50%);
    }

    .guide-image2 {
      position: absolute;
      width: 420px;
      height: auto;
      top: 9vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.swiper-container::-webkit-scrollbar {
  display: none;
}
</style>
